import { createSlice } from "@reduxjs/toolkit";
import { API_CALL_BEGAN } from "../api";
import { isCacheValid } from "../../library/Utilities";
import { studentURL, iLevelApiProxiURL } from "../../AppVars";

const slice = createSlice({
  name: "students",
  initialState: {
    list: [],
    loading: true,
    lastFetch: null,
  },
  reducers: {
    STUDENTS_REQUESTED: (students, action) => {
      students.loading = true;
    },

    STUDENTS_RECEIVED: (students, action) => {
      if (Array.isArray(action.payload)) students.list = action.payload;
      else students.list = [];

      students.loading = false;
      students.lastFetch = Date.now();
    },

    STUDENTS_REQUEST_FAILED: (students, action) => {
      students.loading = false;
    },

    STUDENT_ADD_REQUESTED: (students, action) => {
      students.loading = true;
    },

    STUDENT_ADDED: (students, action) => {
      students.loading = false;
      // students.list.push(action.payload);
      console.log("STUIDENT ADDED ", action);
    },
    STUDENT_ADD_FAILED: (students, action) => {
      students.loading = false;
    },
  },
});

const {
  STUDENTS_RECEIVED,
  STUDENT_ADDED,
  STUDENTS_REQUESTED,
  STUDENTS_REQUEST_FAILED,
  STUDENT_ADD_REQUESTED,
  STUDENT_ADD_FAILED,
} = slice.actions;
export default slice.reducer;

// Action Creators
export const loadStudents = () => (dispatch, getState) => {
  const { lastFetch } = getState().entities.students;
  if (lastFetch && isCacheValid(lastFetch)) return;

  const state = getState();

  dispatch(
    API_CALL_BEGAN({
      url: `${iLevelApiProxiURL}/students`,
      method: "get",
      data: { employerId: state.entities.companies.selectedCompany, pageSize: 2000 },
      onStart: STUDENTS_REQUESTED.type,
      onSuccess: STUDENTS_RECEIVED.type,
      onError: STUDENTS_REQUEST_FAILED.type,
    })
  );
};

export const addStudent = (student) => (dispatch, getState) => {
  console.log("adding student", student);

  dispatch(
    API_CALL_BEGAN({
      url: `${iLevelApiProxiURL}/students`,
      method: "post",
      data: student,
      onStart: STUDENT_ADD_REQUESTED.type,
      onSuccess: STUDENT_ADDED.type,
      onError: STUDENT_ADD_FAILED.type,
    })
  );
};
