import { createSlice } from "@reduxjs/toolkit";
import { API_CALL_BEGAN } from "../api";
import { isCacheValid } from "../../library/Utilities";
import { studentURL, iLevelApiProxiURL } from "../../AppVars";
import { loadStudents } from "./student"

const slice = createSlice({
  name: "companies",
  initialState: {
      list: [],
      lastFetch: null,
      status: 'empty', // status can be 'empty', 'loading', 'succeeded', 'failed'
      selectedCompany: 0
  },
  reducers: {
      COMPANIES_REQUESTED: (companies, action) => {
          companies.status = 'loading';
      },

      COMPANIES_RECIEVED: (companies, action) => {
          console.log('COMPANIES_RECIEVED', action.payload);
          companies.list = action.payload;
          companies.lastFetch = Date.now();
          companies.status = 'succeeded';

          if(companies.selectedCompany <= 0 && companies.list.length > 0){
              companies.selectedCompany = companies.list[0].CompanyID;
          }
      },

      COMPANIES_REQUEST_FAILED: (companies, action) => {
          companies.status = 'failed';
      },

      COMPANIES_UPDATE_SELECTEDCOMPANY: (companies, action) => {
          companies.selectedCompany = action.payload;
          console.log(`COMPANIES_UPDATE_SELECTEDCOMPANY - ${companies.selectedCompany}`)
      }
    }
});

const { COMPANIES_RECIEVED, COMPANIES_REQUESTED, COMPANIES_REQUEST_FAILED, COMPANIES_UPDATE_SELECTEDCOMPANY } = slice.actions;
export default slice.reducer;

// Action Creators

export const loadCompanies = () => (dispatch, getState) => {
    const { lastFetch } = getState().entities.companies;
    if (lastFetch && isCacheValid(lastFetch)) return;

    dispatch(
        API_CALL_BEGAN({
            url: `${iLevelApiProxiURL}/companies/list`,
            method: 'get',
            onStart: COMPANIES_REQUESTED.type,
            onSuccess: COMPANIES_RECIEVED.type,
            onError: COMPANIES_REQUEST_FAILED.type
        })
    );
};

export const updateSelectedCompany = (companyId) => (dispatch, getState) => {
    dispatch({type: COMPANIES_UPDATE_SELECTEDCOMPANY, payload: companyId});
}
