import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { AWS_COGNITO_CONFIG } from "../../AppVars.js";
import axios from 'axios';
import { AUTH_CALL_BEGAN } from '../api.js';

const slice = createSlice({
  name: "authorize",
  initialState: {
    loading: true,
    isLoggedIn: false,
    error: false,
    idToken: null,
    accessToken: null,
    refreshToken: null,
    exp: null
  },
  reducers: {
    SIGNIN_SUCCESS: (authorize, action) => {
      authorize.isLoggedIn = true;
      authorize.loading = false;
    },
    SIGNOUT_REQUEST: (authorize, action) => {
      authorize.isLoggedIn = false;
      authorize.loading = false;
      localStorage.removeItem("ReadySafeUserObj");
    },
    SIGNOUT_SUCCESS: (authorize, action) => {

    },
    SIGNOUT_FAILURE: (authorize, action) => {

    },
    SIGNIN_REQUEST: (authorize, action) => {
      authorize.loading = true;
    },
    SIGNIN_TOKEN_RECIEVED: (authorize, action) => {
      //console.log('authorize', authorize.loading);
      authorize.loading = false;
      authorize.isLoggedIn = true;
      authorize.error = false;
      authorize.idToken = action.payload.id_token;
      authorize.accessToken = action.payload.access_token;
      authorize.refreshToken = action.payload.refresh_token;
      authorize.tokenType = action.payload.token_type;

      localStorage.setItem("ReadySafeUserObj", JSON.stringify({
          "idToken": authorize.idToken,
          "accessToken": authorize.accessToken,
          "refreshToken": authorize.refreshToken,
          "exp": Date.now()+(action.payload.expires_in*1000),
          "tokenType": authorize.tokenType
        }));
    },
    SIGNIN_FAILURE: (authorize, action) => {
      authorize.loading = false;
      authorize.isLoggedIn = false;
      authorize.error = true;
    },
    VERIFY: (authorize, action) => {
      return { ...authorize };
    },
    SETUSER: (authorize, action) => {
      return { ...authorize };
    },
  },
});

export const { SIGNIN_SUCCESS, SIGNOUT_REQUEST, SIGNIN_REQUEST, SIGNIN_TOKEN_RECIEVED, SIGNIN_FAILURE, VERIFY, SETUSER, SIGNOUT_SUCCESS, SIGNOUT_FAILURE } = slice.actions;
export default slice.reducer;

//function to parse a query string into a key value pair object { 'param1': 'value1', 'param2': 'value2'};
//this was taken from stackoverflow https://stackoverflow.com/questions/2090551/parse-query-string-in-javascript
function parseQuery(queryString) {
    var query = {};
    var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
}

//const dispatch = useDispatch;

export const HandleSignIn = (authCode) => (dispatch, getState) => {
  console.log(`Auth-handleSignIn`, authCode);
  let st = getState();
  //console.log('st:', st.authorize.isLoggedIn);

  /*  Auth Workflow Process:
      1. See if QueryString for auth code is present in URL request.
        A. If '?code=' present in URL means user has been redirected from cognito
          i. Exchange code for user tokens and store tokens in browserStorage.
            1a. If auth code is invalid redirect user back to the hosted UI
            1b. Auth code is valid exchange for tokens, store tokens in browserStorage,
                and direct user to dashboard and load data.

      2. No Auth Code in Query - See if a user object is already stored in browserStorage.
      User Object consists of ID Token, Access Token, Refresh Token Token Expiration, and
      Token type.
        Example Object: {
            ID: <token>,
            Access: <token>,
            RefreshToken: <refresh token>
            Exp: <date in ms>,
            Type: 'Bearer'
          }

        A. if object is found check expiration if token is still valid.
          1a. Token is VALID allow user to dashboard and load data from token.
          1b. Token is INVALID use refresh token to get new access token.

        B.  if no user object is found send them through the Cognito UI and loop through this
            process again.

  */

  //if we have code in the query string we are going to extract it and exchange it for tokens
  if(authCode && !st.authorize.isLoggedIn){
    console.log('AUTHORIZATION_CODE present');
    return dispatch(getTokens(authCode.code, null));
  }else{
    //no code in the query string we check for tokens in browserStorage.  We are not looking in
    //sessionStorage since we want to tokens to live beyond the session if they are still vaid.
    console.log('st:', st.authorize.isLoggedIn);
    let ReadySafeUserObj = JSON.parse(window.localStorage.getItem('ReadySafeUserObj'));

    if(st.authorize.isLoggedIn || ReadySafeUserObj){
      //console.log('ReadySafeUserObj', ReadySafeUserObj);

      if(ReadySafeUserObj.exp > Date.now()){
        //Token is not expired so we are going to let them through to the dashboard.
        console.log('TOKENS HAVE BEEN FOUND');
        return dispatch({type: SIGNIN_SUCCESS});
      }else{
        console.log('TOKENS HAVE BEEN FOUND - EXPIRED');
        //Token is expired we need to get the Refresh token if available and consume it for a new
        //tokens
        if(ReadySafeUserObj.refreshToken){
            console.log('Refresh Token found exchanging');
            return dispatch(getTokens(null, ReadySafeUserObj.refreshToken));
        }
        return dispatch({type: SIGNOUT_REQUEST});
      }   //console.log(`${ReadySafeUserObj.exp} : ${Date.now()}`);

    }else{
      console.log('Send User to Cognito');
      //There is no Token in localStorage and there is no Authorization code.  User is redirected to
      //Cognito Hosted UI.
      return dispatch({type: SIGNOUT_REQUEST});
    }

  }
};


export const getTokens = (code, token) => AUTH_CALL_BEGAN({
  authCode: code,
  authRefreshToken: token,
  authRedirectUri: window.location.origin + '/auth',
  authStart: SIGNIN_REQUEST.type,
  authSuccess: SIGNIN_TOKEN_RECIEVED.type,
  authFailure: SIGNIN_FAILURE.type
});

export const signOut = (token) => AUTH_CALL_BEGAN({
    authRefreshToken: token,
    authRedirectUri: AWS_COGNITO_CONFIG.Oauth.RedirectSignOut,
    authStart: SIGNOUT_REQUEST.type,
    authSuccess: SIGNOUT_SUCCESS.type,
    authFailure: SIGNOUT_FAILURE.type
})

export const handleSignOut = () => (dispatch, getState) => {
    console.log('Calling Signout to Auth Service');
    let ReadySafeUserObj = JSON.parse(window.localStorage.getItem('ReadySafeUserObj'));
    return dispatch(signOut(ReadySafeUserObj.refreshToken));
}
